import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Anchor from "../components/Anchor"
import BlogContainerBase from "../components/BlogContainer"
import { devices } from "../styles/media"

// TODO: Zen mode

function Blog({ data = {} }) {
  // const [zenmode, setZenmode] = React.useState(false)
  const { markdownRemark: post } = data
  return (
    <Layout>
      <SEO
        title={`Post - ${post.frontmatter.title}`}
        description={post.frontmatter.description}
      />
      {/* <Zen zen={zenmode}> */}
      <BlogContainer>
        <Title>{post.frontmatter.title}</Title>
        <Section>
          <SubtitleText>
            <time dateTime={`${post.frontmatter.datetime}T00:00:00Z`}>
              {post.frontmatter.displayDate}
            </time>
          </SubtitleText>
          {post.frontmatter.talkLink && (
            <SubtitleText>
              <Anchor href={post.frontmatter.talkLink}>
                {post.frontmatter.talk || post.frontmatter.talkLink}&ensp;↗
              </Anchor>
            </SubtitleText>
          )}
          <PostArticle>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </PostArticle>
        </Section>
      </BlogContainer>
      {/* </Zen> */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($id: String!) {
    markdownRemark(frontmatter: { path: { eq: $id } }) {
      html
      frontmatter {
        displayDate: date(formatString: "D MMMM YYYY")
        datetime: date(formatString: "YYYY-MM-DD")
        description
        path
        title
        talk
        talkLink
      }
    }
  }
`

export default Blog

const BlogContainer = styled(BlogContainerBase)`
  padding: 0px;
`
const Section = styled("section")`
  padding: 20px;
  padding-top: 0px;
`
const Zen = styled("div")`
  ${({ zen }) =>
    zen &&
    `
    position: fixed;
    background-color: aliceblue;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    overflow: scroll;
  `}
`
const Title = styled("h1")`
  display: inline-block;
  margin: 10px 20px 5px 20px;
  padding: 20px;
  background-color: ${({ theme }) => theme.contrast};
  color: ${({ theme }) => theme.background};
  @media ${devices.tablet} {
    display: block;
    margin: 10px 0px;
  }
`
const SubtitleText = styled("aside")`
  color: ${({ theme }) => theme.secondary1};
  font-size: 0.875rem;
  font-style: italic;
`
const PostArticle = styled("article")`
  margin: 20px 0px;
`
